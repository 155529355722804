@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');


.pass-style{
  font-family: 'Dancing Script', cursive;
  color: #eeeeee;
  font-size: 30px;
}

.fail-style{
  font-family: 'Dancing Script', cursive;
  color: #eeeeee;
  font-size: 30px;
}

.React-Main{
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  padding:2px
}

.level-fail{
  position: absolute;
  background-color: #de3c3c;
  height: 390px;
  aspect-ratio: 1;
  top: 15px;
  left:15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.level-pass{
  position: absolute;
  background-color: #86cc5a;
  height: 390px;
  aspect-ratio: 1;
  top: 15px;
  left:15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.boardStyle{
  margin: 20px 20px 10px;
  height: 420px;
  aspect-ratio: 1;
  background-color: #282c34;
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.logoimage{
  padding: 5px;
}

.Banner{
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding:5px;
  color: #eeeeee;
}

.message{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttons{
  padding: 5px;
}

.moves{
  color: #eeeeee;
  margin-top: 5px;
  font-family: 'Dancing Script', cursive;
  font-size:25px;
}

.Main-Head{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonesh{
  margin: 5px;
}

.buttonesh:hover{
  opacity: 0.7;
}

.resetbutton{
  background-color: darkslateblue;
  align-self: center;
  font-size: 10px;
  align-self: start;
  color: white;
  font-family: 'Dancing Script', cursive;
  font-size:larger;
  margin-top: 10px;
}

.number{
  color: #de3c3c;
}

.level{
  color: darkorange;
}

.container{
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.infobox{
  color:white;
  padding: 5px;
  font-family: 'Dancing Script', cursive;
  font-size: 23px;
}

.button-success {
  background-image: linear-gradient(
    to right,
    #4cb8c4 0%,
    #3cd3ad 51%,
    #4cb8c4 100%
  );
}
.button-success  {
  margin: 10px;
  padding: 5px 20px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 100px;
  display: block;
  color:#222;
  font-family: 'Dancing Script', cursive;
  font-size: larger;
}

.button-success:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}




.button-fail{
  background-image: linear-gradient(
    to right,
    #f2994a 0%,
    #f2c94c 51%,
    #f2994a 100%
  );
}
.button-fail {
  margin: 10px;
  padding: 5px 20px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 100px;
  display: block;
  color:#222;
  font-family: 'Dancing Script', cursive;
  font-size: larger;
}

.button-fail:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

@media only screen and (max-width: 500px) {
  .React-Main {
    flex-direction: column;
    padding-top:0.7px;
  }
  .infobox{
    max-width: 300px;
    font-size: 15px;
    padding-top: 0.75px;
    padding-bottom: 0.75px;
  }
  .boardStyle{
    height: 280px;
  }
  .level-fail{
    height: 260px;
    top: 10px;
    left:10px;
  }
  .level-pass{
    height: 260px;
    top: 10px;
    left:10px;
  }
  .moves{
  color: #eeeeee;
  margin-top: 5px;
  font-family: 'Dancing Script', cursive;
  font-size:20px;
  }
  .Banner{
    padding-bottom:0.75px;  
  }
}