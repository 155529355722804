/* body {
    margin: 0;
    overflow: hidden;
  } */
  
  .wrapper {
    position: relative;
    min-height: 50vh;
    z-index: 200;
  }
  
  [class|=confetti] {
    position: absolute;
    z-index: 500;
  }
  
  .confetti-0 {
    width: 5px;
    height: 2px;
    background-color: #d13447;
    top: -10%;
    left: 47%;
    opacity: 1.0622874395;
    transform: rotate(255.0514309746deg);
    -webkit-animation: drop-0 4.5066265739s 0.7870836247s infinite;
            animation: drop-0 4.5066265739s 0.7870836247s infinite;
  }
  
  @-webkit-keyframes drop-0 {
    100% {
      top: 110%;
      left: 54%;
    }
  }
  
  @keyframes drop-0 {
    100% {
      top: 110%;
      left: 54%;
    }
  }
  .confetti-1 {
    width: 1px;
    height: 0.4px;
    background-color: #ffbf00;
    top: -10%;
    left: 6%;
    opacity: 1.4921321129;
    transform: rotate(287.9686705126deg);
    -webkit-animation: drop-1 4.8268884283s 0.6892119269s infinite;
            animation: drop-1 4.8268884283s 0.6892119269s infinite;
  }
  
  @-webkit-keyframes drop-1 {
    100% {
      top: 110%;
      left: 14%;
    }
  }
  
  @keyframes drop-1 {
    100% {
      top: 110%;
      left: 14%;
    }
  }
  .confetti-2 {
    width: 6px;
    height: 2.4px;
    background-color: #263672;
    top: -10%;
    left: 15%;
    opacity: 1.4238824486;
    transform: rotate(274.2477507436deg);
    -webkit-animation: drop-2 4.9589006728s 0.2143973115s infinite;
            animation: drop-2 4.9589006728s 0.2143973115s infinite;
  }
  
  @-webkit-keyframes drop-2 {
    100% {
      top: 110%;
      left: 23%;
    }
  }
  
  @keyframes drop-2 {
    100% {
      top: 110%;
      left: 23%;
    }
  }
  .confetti-3 {
    width: 1px;
    height: 0.4px;
    background-color: #d13447;
    top: -10%;
    left: 22%;
    opacity: 1.3270225167;
    transform: rotate(134.5728055412deg);
    -webkit-animation: drop-3 4.1417074987s 0.7133543256s infinite;
            animation: drop-3 4.1417074987s 0.7133543256s infinite;
  }
  
  @-webkit-keyframes drop-3 {
    100% {
      top: 110%;
      left: 31%;
    }
  }
  
  @keyframes drop-3 {
    100% {
      top: 110%;
      left: 31%;
    }
  }
  .confetti-4 {
    width: 3px;
    height: 1.2px;
    background-color: #263672;
    top: -10%;
    left: 100%;
    opacity: 1.4623906603;
    transform: rotate(320.6468338546deg);
    -webkit-animation: drop-4 4.9248616689s 0.2816682164s infinite;
            animation: drop-4 4.9248616689s 0.2816682164s infinite;
  }
  
  @-webkit-keyframes drop-4 {
    100% {
      top: 110%;
      left: 115%;
    }
  }
  
  @keyframes drop-4 {
    100% {
      top: 110%;
      left: 115%;
    }
  }
  .confetti-5 {
    width: 6px;
    height: 2.4px;
    background-color: #263672;
    top: -10%;
    left: 34%;
    opacity: 1.3495919533;
    transform: rotate(333.063659658deg);
    -webkit-animation: drop-5 4.5043820984s 0.8144855189s infinite;
            animation: drop-5 4.5043820984s 0.8144855189s infinite;
  }
  
  @-webkit-keyframes drop-5 {
    100% {
      top: 110%;
      left: 43%;
    }
  }
  
  @keyframes drop-5 {
    100% {
      top: 110%;
      left: 43%;
    }
  }
  .confetti-6 {
    width: 6px;
    height: 2.4px;
    background-color: #ffbf00;
    top: -10%;
    left: 6%;
    opacity: 0.8673419318;
    transform: rotate(203.6672251481deg);
    -webkit-animation: drop-6 4.9712852493s 0.8001107744s infinite;
            animation: drop-6 4.9712852493s 0.8001107744s infinite;
  }
  
  @-webkit-keyframes drop-6 {
    100% {
      top: 110%;
      left: 19%;
    }
  }
  
  @keyframes drop-6 {
    100% {
      top: 110%;
      left: 19%;
    }
  }
  .confetti-7 {
    width: 8px;
    height: 3.2px;
    background-color: #ffbf00;
    top: -10%;
    left: 75%;
    opacity: 0.5077821017;
    transform: rotate(74.660109635deg);
    -webkit-animation: drop-7 4.8769609725s 0.662650047s infinite;
            animation: drop-7 4.8769609725s 0.662650047s infinite;
  }
  
  @-webkit-keyframes drop-7 {
    100% {
      top: 110%;
      left: 76%;
    }
  }
  
  @keyframes drop-7 {
    100% {
      top: 110%;
      left: 76%;
    }
  }
  .confetti-8 {
    width: 3px;
    height: 1.2px;
    background-color: #d13447;
    top: -10%;
    left: 94%;
    opacity: 0.6226435769;
    transform: rotate(237.9566099122deg);
    -webkit-animation: drop-8 4.8380462382s 0.8554749064s infinite;
            animation: drop-8 4.8380462382s 0.8554749064s infinite;
  }
  
  @-webkit-keyframes drop-8 {
    100% {
      top: 110%;
      left: 103%;
    }
  }
  
  @keyframes drop-8 {
    100% {
      top: 110%;
      left: 103%;
    }
  }
  .confetti-9 {
    width: 5px;
    height: 2px;
    background-color: #ffbf00;
    top: -10%;
    left: 71%;
    opacity: 1.227624969;
    transform: rotate(251.5074212751deg);
    -webkit-animation: drop-9 4.1079595179s 0.1394634893s infinite;
            animation: drop-9 4.1079595179s 0.1394634893s infinite;
  }
  
  @-webkit-keyframes drop-9 {
    100% {
      top: 110%;
      left: 80%;
    }
  }
  
  @keyframes drop-9 {
    100% {
      top: 110%;
      left: 80%;
    }
  }
  .confetti-10 {
    width: 2px;
    height: 0.8px;
    background-color: #ffbf00;
    top: -10%;
    left: 73%;
    opacity: 1.4920311912;
    transform: rotate(44.9352189567deg);
    -webkit-animation: drop-10 4.8211252914s 0.0347109759s infinite;
            animation: drop-10 4.8211252914s 0.0347109759s infinite;
  }
  
  @-webkit-keyframes drop-10 {
    100% {
      top: 110%;
      left: 75%;
    }
  }
  
  @keyframes drop-10 {
    100% {
      top: 110%;
      left: 75%;
    }
  }
  .confetti-11 {
    width: 7px;
    height: 2.8px;
    background-color: #263672;
    top: -10%;
    left: 60%;
    opacity: 1.075518306;
    transform: rotate(313.4894899452deg);
    -webkit-animation: drop-11 4.8513411974s 0.9313987388s infinite;
            animation: drop-11 4.8513411974s 0.9313987388s infinite;
  }
  
  @-webkit-keyframes drop-11 {
    100% {
      top: 110%;
      left: 75%;
    }
  }
  
  @keyframes drop-11 {
    100% {
      top: 110%;
      left: 75%;
    }
  }
  .confetti-12 {
    width: 6px;
    height: 2.4px;
    background-color: #263672;
    top: -10%;
    left: 41%;
    opacity: 1.1038582328;
    transform: rotate(13.451011996deg);
    -webkit-animation: drop-12 4.7760353487s 0.214141366s infinite;
            animation: drop-12 4.7760353487s 0.214141366s infinite;
  }
  
  @-webkit-keyframes drop-12 {
    100% {
      top: 110%;
      left: 54%;
    }
  }
  
  @keyframes drop-12 {
    100% {
      top: 110%;
      left: 54%;
    }
  }
  .confetti-13 {
    width: 8px;
    height: 3.2px;
    background-color: #263672;
    top: -10%;
    left: 46%;
    opacity: 0.7553260317;
    transform: rotate(169.1379505223deg);
    -webkit-animation: drop-13 4.8822542246s 0.2436663695s infinite;
            animation: drop-13 4.8822542246s 0.2436663695s infinite;
  }
  
  @-webkit-keyframes drop-13 {
    100% {
      top: 110%;
      left: 48%;
    }
  }
  
  @keyframes drop-13 {
    100% {
      top: 110%;
      left: 48%;
    }
  }
  .confetti-14 {
    width: 3px;
    height: 1.2px;
    background-color: #263672;
    top: -10%;
    left: 85%;
    opacity: 0.6241152366;
    transform: rotate(158.3229735884deg);
    -webkit-animation: drop-14 4.4944659713s 0.013316877s infinite;
            animation: drop-14 4.4944659713s 0.013316877s infinite;
  }
  
  @-webkit-keyframes drop-14 {
    100% {
      top: 110%;
      left: 87%;
    }
  }
  
  @keyframes drop-14 {
    100% {
      top: 110%;
      left: 87%;
    }
  }
  .confetti-15 {
    width: 4px;
    height: 1.6px;
    background-color: #ffbf00;
    top: -10%;
    left: 32%;
    opacity: 1.4777176248;
    transform: rotate(306.5253985445deg);
    -webkit-animation: drop-15 4.1511086249s 0.8611085579s infinite;
            animation: drop-15 4.1511086249s 0.8611085579s infinite;
  }
  
  @-webkit-keyframes drop-15 {
    100% {
      top: 110%;
      left: 45%;
    }
  }
  
  @keyframes drop-15 {
    100% {
      top: 110%;
      left: 45%;
    }
  }
  .confetti-16 {
    width: 5px;
    height: 2px;
    background-color: #d13447;
    top: -10%;
    left: 79%;
    opacity: 1.300149747;
    transform: rotate(59.8893779897deg);
    -webkit-animation: drop-16 4.2921071856s 0.5182231539s infinite;
            animation: drop-16 4.2921071856s 0.5182231539s infinite;
  }
  
  @-webkit-keyframes drop-16 {
    100% {
      top: 110%;
      left: 84%;
    }
  }
  
  @keyframes drop-16 {
    100% {
      top: 110%;
      left: 84%;
    }
  }
  .confetti-17 {
    width: 8px;
    height: 3.2px;
    background-color: #ffbf00;
    top: -10%;
    left: 24%;
    opacity: 0.5200681489;
    transform: rotate(267.365507034deg);
    -webkit-animation: drop-17 4.2065798714s 0.8269771754s infinite;
            animation: drop-17 4.2065798714s 0.8269771754s infinite;
  }
  
  @-webkit-keyframes drop-17 {
    100% {
      top: 110%;
      left: 27%;
    }
  }
  
  @keyframes drop-17 {
    100% {
      top: 110%;
      left: 27%;
    }
  }
  .confetti-18 {
    width: 7px;
    height: 2.8px;
    background-color: #263672;
    top: -10%;
    left: 77%;
    opacity: 1.0609266299;
    transform: rotate(206.5928766462deg);
    -webkit-animation: drop-18 4.5522753671s 0.2522974457s infinite;
            animation: drop-18 4.5522753671s 0.2522974457s infinite;
  }
  
  @-webkit-keyframes drop-18 {
    100% {
      top: 110%;
      left: 88%;
    }
  }
  
  @keyframes drop-18 {
    100% {
      top: 110%;
      left: 88%;
    }
  }
  .confetti-19 {
    width: 7px;
    height: 2.8px;
    background-color: #263672;
    top: -10%;
    left: 18%;
    opacity: 0.638841133;
    transform: rotate(91.2023505358deg);
    -webkit-animation: drop-19 4.266644496s 0.0075190093s infinite;
            animation: drop-19 4.266644496s 0.0075190093s infinite;
  }
  
  @-webkit-keyframes drop-19 {
    100% {
      top: 110%;
      left: 20%;
    }
  }
  
  @keyframes drop-19 {
    100% {
      top: 110%;
      left: 20%;
    }
  }
  .confetti-20 {
    width: 8px;
    height: 3.2px;
    background-color: #d13447;
    top: -10%;
    left: 55%;
    opacity: 1.2304828251;
    transform: rotate(167.4658765347deg);
    -webkit-animation: drop-20 4.0633063149s 0.3253897673s infinite;
            animation: drop-20 4.0633063149s 0.3253897673s infinite;
  }
  
  @-webkit-keyframes drop-20 {
    100% {
      top: 110%;
      left: 62%;
    }
  }
  
  @keyframes drop-20 {
    100% {
      top: 110%;
      left: 62%;
    }
  }
  .confetti-21 {
    width: 8px;
    height: 3.2px;
    background-color: #ffbf00;
    top: -10%;
    left: 18%;
    opacity: 1.2005499087;
    transform: rotate(224.397968576deg);
    -webkit-animation: drop-21 4.6516193794s 0.5806724643s infinite;
            animation: drop-21 4.6516193794s 0.5806724643s infinite;
  }
  
  @-webkit-keyframes drop-21 {
    100% {
      top: 110%;
      left: 30%;
    }
  }
  
  @keyframes drop-21 {
    100% {
      top: 110%;
      left: 30%;
    }
  }
  .confetti-22 {
    width: 4px;
    height: 1.6px;
    background-color: #ffbf00;
    top: -10%;
    left: 28%;
    opacity: 0.6866434384;
    transform: rotate(1.53603977deg);
    -webkit-animation: drop-22 4.9591417142s 0.4979555066s infinite;
            animation: drop-22 4.9591417142s 0.4979555066s infinite;
  }
  
  @-webkit-keyframes drop-22 {
    100% {
      top: 110%;
      left: 31%;
    }
  }
  
  @keyframes drop-22 {
    100% {
      top: 110%;
      left: 31%;
    }
  }
  .confetti-23 {
    width: 4px;
    height: 1.6px;
    background-color: #d13447;
    top: -10%;
    left: 42%;
    opacity: 1.4319426232;
    transform: rotate(217.7065006103deg);
    -webkit-animation: drop-23 4.3263676352s 0.4610311684s infinite;
            animation: drop-23 4.3263676352s 0.4610311684s infinite;
  }
  
  @-webkit-keyframes drop-23 {
    100% {
      top: 110%;
      left: 48%;
    }
  }
  
  @keyframes drop-23 {
    100% {
      top: 110%;
      left: 48%;
    }
  }
  .confetti-24 {
    width: 7px;
    height: 2.8px;
    background-color: #d13447;
    top: -10%;
    left: 43%;
    opacity: 1.1928168547;
    transform: rotate(30.550211615deg);
    -webkit-animation: drop-24 4.962011495s 0.0279203527s infinite;
            animation: drop-24 4.962011495s 0.0279203527s infinite;
  }
  
  @-webkit-keyframes drop-24 {
    100% {
      top: 110%;
      left: 46%;
    }
  }
  
  @keyframes drop-24 {
    100% {
      top: 110%;
      left: 46%;
    }
  }
  .confetti-25 {
    width: 3px;
    height: 1.2px;
    background-color: #d13447;
    top: -10%;
    left: 3%;
    opacity: 0.5322234745;
    transform: rotate(114.2445708476deg);
    -webkit-animation: drop-25 4.6357768754s 0.1764730061s infinite;
            animation: drop-25 4.6357768754s 0.1764730061s infinite;
  }
  
  @-webkit-keyframes drop-25 {
    100% {
      top: 110%;
      left: 14%;
    }
  }
  
  @keyframes drop-25 {
    100% {
      top: 110%;
      left: 14%;
    }
  }
  .confetti-26 {
    width: 3px;
    height: 1.2px;
    background-color: #ffbf00;
    top: -10%;
    left: 95%;
    opacity: 0.8313318422;
    transform: rotate(285.2189407879deg);
    -webkit-animation: drop-26 4.9808795923s 0.4272839262s infinite;
            animation: drop-26 4.9808795923s 0.4272839262s infinite;
  }
  
  @-webkit-keyframes drop-26 {
    100% {
      top: 110%;
      left: 100%;
    }
  }
  
  @keyframes drop-26 {
    100% {
      top: 110%;
      left: 100%;
    }
  }
  .confetti-27 {
    width: 8px;
    height: 3.2px;
    background-color: #ffbf00;
    top: -10%;
    left: 22%;
    opacity: 0.9283587073;
    transform: rotate(94.3189776675deg);
    -webkit-animation: drop-27 4.4099136918s 0.1621586095s infinite;
            animation: drop-27 4.4099136918s 0.1621586095s infinite;
  }
  
  @-webkit-keyframes drop-27 {
    100% {
      top: 110%;
      left: 32%;
    }
  }
  
  @keyframes drop-27 {
    100% {
      top: 110%;
      left: 32%;
    }
  }
  .confetti-28 {
    width: 7px;
    height: 2.8px;
    background-color: #263672;
    top: -10%;
    left: 16%;
    opacity: 0.9116891301;
    transform: rotate(50.9274989972deg);
    -webkit-animation: drop-28 4.771201674s 0.6523834795s infinite;
            animation: drop-28 4.771201674s 0.6523834795s infinite;
  }
  
  @-webkit-keyframes drop-28 {
    100% {
      top: 110%;
      left: 20%;
    }
  }
  
  @keyframes drop-28 {
    100% {
      top: 110%;
      left: 20%;
    }
  }
  .confetti-29 {
    width: 4px;
    height: 1.6px;
    background-color: #263672;
    top: -10%;
    left: 43%;
    opacity: 0.6236812147;
    transform: rotate(220.144321691deg);
    -webkit-animation: drop-29 4.5750527457s 0.0016806199s infinite;
            animation: drop-29 4.5750527457s 0.0016806199s infinite;
  }
  
  @-webkit-keyframes drop-29 {
    100% {
      top: 110%;
      left: 50%;
    }
  }
  
  @keyframes drop-29 {
    100% {
      top: 110%;
      left: 50%;
    }
  }
  .confetti-30 {
    width: 1px;
    height: 0.4px;
    background-color: #ffbf00;
    top: -10%;
    left: 48%;
    opacity: 1.2906063023;
    transform: rotate(52.1770066729deg);
    -webkit-animation: drop-30 4.4430609485s 0.7140433652s infinite;
            animation: drop-30 4.4430609485s 0.7140433652s infinite;
  }
  
  @-webkit-keyframes drop-30 {
    100% {
      top: 110%;
      left: 55%;
    }
  }
  
  @keyframes drop-30 {
    100% {
      top: 110%;
      left: 55%;
    }
  }
  .confetti-31 {
    width: 1px;
    height: 0.4px;
    background-color: #ffbf00;
    top: -10%;
    left: 82%;
    opacity: 1.1978682055;
    transform: rotate(262.1377213365deg);
    -webkit-animation: drop-31 4.8539153125s 0.0827590776s infinite;
            animation: drop-31 4.8539153125s 0.0827590776s infinite;
  }
  
  @-webkit-keyframes drop-31 {
    100% {
      top: 110%;
      left: 83%;
    }
  }
  
  @keyframes drop-31 {
    100% {
      top: 110%;
      left: 83%;
    }
  }
  .confetti-32 {
    width: 1px;
    height: 0.4px;
    background-color: #d13447;
    top: -10%;
    left: 16%;
    opacity: 1.4395936601;
    transform: rotate(99.7552825282deg);
    -webkit-animation: drop-32 4.3947279246s 0.6328999409s infinite;
            animation: drop-32 4.3947279246s 0.6328999409s infinite;
  }
  
  @-webkit-keyframes drop-32 {
    100% {
      top: 110%;
      left: 29%;
    }
  }
  
  @keyframes drop-32 {
    100% {
      top: 110%;
      left: 29%;
    }
  }
  .confetti-33 {
    width: 3px;
    height: 1.2px;
    background-color: #263672;
    top: -10%;
    left: 26%;
    opacity: 1.4023287079;
    transform: rotate(297.4620653431deg);
    -webkit-animation: drop-33 4.0413104593s 0.4105763063s infinite;
            animation: drop-33 4.0413104593s 0.4105763063s infinite;
  }
  
  @-webkit-keyframes drop-33 {
    100% {
      top: 110%;
      left: 40%;
    }
  }
  
  @keyframes drop-33 {
    100% {
      top: 110%;
      left: 40%;
    }
  }
  .confetti-34 {
    width: 2px;
    height: 0.8px;
    background-color: #ffbf00;
    top: -10%;
    left: 51%;
    opacity: 0.5224204302;
    transform: rotate(110.0696572912deg);
    -webkit-animation: drop-34 4.1783216476s 0.5746747185s infinite;
            animation: drop-34 4.1783216476s 0.5746747185s infinite;
  }
  
  @-webkit-keyframes drop-34 {
    100% {
      top: 110%;
      left: 64%;
    }
  }
  
  @keyframes drop-34 {
    100% {
      top: 110%;
      left: 64%;
    }
  }
  .confetti-35 {
    width: 1px;
    height: 0.4px;
    background-color: #263672;
    top: -10%;
    left: 37%;
    opacity: 0.8452405556;
    transform: rotate(195.5720090615deg);
    -webkit-animation: drop-35 4.3733616531s 0.3005184645s infinite;
            animation: drop-35 4.3733616531s 0.3005184645s infinite;
  }
  
  @-webkit-keyframes drop-35 {
    100% {
      top: 110%;
      left: 46%;
    }
  }
  
  @keyframes drop-35 {
    100% {
      top: 110%;
      left: 46%;
    }
  }
  .confetti-36 {
    width: 5px;
    height: 2px;
    background-color: #ffbf00;
    top: -10%;
    left: 44%;
    opacity: 0.5484493399;
    transform: rotate(206.5138676264deg);
    -webkit-animation: drop-36 4.4803882708s 0.2620110117s infinite;
            animation: drop-36 4.4803882708s 0.2620110117s infinite;
  }
  
  @-webkit-keyframes drop-36 {
    100% {
      top: 110%;
      left: 55%;
    }
  }
  
  @keyframes drop-36 {
    100% {
      top: 110%;
      left: 55%;
    }
  }
  .confetti-37 {
    width: 8px;
    height: 3.2px;
    background-color: #d13447;
    top: -10%;
    left: 15%;
    opacity: 0.5858679387;
    transform: rotate(190.3075718166deg);
    -webkit-animation: drop-37 4.2216795283s 0.3039445813s infinite;
            animation: drop-37 4.2216795283s 0.3039445813s infinite;
  }
  
  @-webkit-keyframes drop-37 {
    100% {
      top: 110%;
      left: 18%;
    }
  }
  
  @keyframes drop-37 {
    100% {
      top: 110%;
      left: 18%;
    }
  }
  .confetti-38 {
    width: 4px;
    height: 1.6px;
    background-color: #263672;
    top: -10%;
    left: 92%;
    opacity: 1.1024323847;
    transform: rotate(312.442105922deg);
    -webkit-animation: drop-38 4.0055500593s 0.86185682s infinite;
            animation: drop-38 4.0055500593s 0.86185682s infinite;
  }
  
  @-webkit-keyframes drop-38 {
    100% {
      top: 110%;
      left: 101%;
    }
  }
  
  @keyframes drop-38 {
    100% {
      top: 110%;
      left: 101%;
    }
  }
  .confetti-39 {
    width: 6px;
    height: 2.4px;
    background-color: #ffbf00;
    top: -10%;
    left: 41%;
    opacity: 0.8096385451;
    transform: rotate(339.4502409097deg);
    -webkit-animation: drop-39 4.7464522129s 0.2021164288s infinite;
            animation: drop-39 4.7464522129s 0.2021164288s infinite;
  }
  
  @-webkit-keyframes drop-39 {
    100% {
      top: 110%;
      left: 53%;
    }
  }
  
  @keyframes drop-39 {
    100% {
      top: 110%;
      left: 53%;
    }
  }
  .confetti-40 {
    width: 5px;
    height: 2px;
    background-color: #263672;
    top: -10%;
    left: 49%;
    opacity: 1.4659297813;
    transform: rotate(36.7718982006deg);
    -webkit-animation: drop-40 4.8173177523s 0.8205603478s infinite;
            animation: drop-40 4.8173177523s 0.8205603478s infinite;
  }
  
  @-webkit-keyframes drop-40 {
    100% {
      top: 110%;
      left: 61%;
    }
  }
  
  @keyframes drop-40 {
    100% {
      top: 110%;
      left: 61%;
    }
  }
  .confetti-41 {
    width: 8px;
    height: 3.2px;
    background-color: #263672;
    top: -10%;
    left: 13%;
    opacity: 0.9291986962;
    transform: rotate(353.0134000929deg);
    -webkit-animation: drop-41 4.7196291904s 0.8682738084s infinite;
            animation: drop-41 4.7196291904s 0.8682738084s infinite;
  }
  
  @-webkit-keyframes drop-41 {
    100% {
      top: 110%;
      left: 26%;
    }
  }
  
  @keyframes drop-41 {
    100% {
      top: 110%;
      left: 26%;
    }
  }
  .confetti-42 {
    width: 2px;
    height: 0.8px;
    background-color: #263672;
    top: -10%;
    left: 22%;
    opacity: 1.2767235447;
    transform: rotate(267.076633636deg);
    -webkit-animation: drop-42 4.4427683402s 0.1129574554s infinite;
            animation: drop-42 4.4427683402s 0.1129574554s infinite;
  }
  
  @-webkit-keyframes drop-42 {
    100% {
      top: 110%;
      left: 31%;
    }
  }
  
  @keyframes drop-42 {
    100% {
      top: 110%;
      left: 31%;
    }
  }
  .confetti-43 {
    width: 2px;
    height: 0.8px;
    background-color: #d13447;
    top: -10%;
    left: 60%;
    opacity: 0.5663614668;
    transform: rotate(104.0286977197deg);
    -webkit-animation: drop-43 4.7133750839s 0.2425796695s infinite;
            animation: drop-43 4.7133750839s 0.2425796695s infinite;
  }
  
  @-webkit-keyframes drop-43 {
    100% {
      top: 110%;
      left: 68%;
    }
  }
  
  @keyframes drop-43 {
    100% {
      top: 110%;
      left: 68%;
    }
  }
  .confetti-44 {
    width: 1px;
    height: 0.4px;
    background-color: #263672;
    top: -10%;
    left: 56%;
    opacity: 0.8598642483;
    transform: rotate(18.2750272563deg);
    -webkit-animation: drop-44 4.9684593264s 0.4317441683s infinite;
            animation: drop-44 4.9684593264s 0.4317441683s infinite;
  }
  
  @-webkit-keyframes drop-44 {
    100% {
      top: 110%;
      left: 63%;
    }
  }
  
  @keyframes drop-44 {
    100% {
      top: 110%;
      left: 63%;
    }
  }
  .confetti-45 {
    width: 4px;
    height: 1.6px;
    background-color: #d13447;
    top: -10%;
    left: 14%;
    opacity: 1.2856703735;
    transform: rotate(322.3983531162deg);
    -webkit-animation: drop-45 4.788058984s 0.4987776667s infinite;
            animation: drop-45 4.788058984s 0.4987776667s infinite;
  }
  
  @-webkit-keyframes drop-45 {
    100% {
      top: 110%;
      left: 17%;
    }
  }
  
  @keyframes drop-45 {
    100% {
      top: 110%;
      left: 17%;
    }
  }
  .confetti-46 {
    width: 2px;
    height: 0.8px;
    background-color: #ffbf00;
    top: -10%;
    left: 85%;
    opacity: 1.1332848447;
    transform: rotate(110.9062724327deg);
    -webkit-animation: drop-46 4.7434779391s 0.3266277869s infinite;
            animation: drop-46 4.7434779391s 0.3266277869s infinite;
  }
  
  @-webkit-keyframes drop-46 {
    100% {
      top: 110%;
      left: 94%;
    }
  }
  
  @keyframes drop-46 {
    100% {
      top: 110%;
      left: 94%;
    }
  }
  .confetti-47 {
    width: 8px;
    height: 3.2px;
    background-color: #ffbf00;
    top: -10%;
    left: 13%;
    opacity: 1.3712409942;
    transform: rotate(225.8958194282deg);
    -webkit-animation: drop-47 4.9079713336s 0.8644462329s infinite;
            animation: drop-47 4.9079713336s 0.8644462329s infinite;
  }
  
  @-webkit-keyframes drop-47 {
    100% {
      top: 110%;
      left: 16%;
    }
  }
  
  @keyframes drop-47 {
    100% {
      top: 110%;
      left: 16%;
    }
  }
  .confetti-48 {
    width: 3px;
    height: 1.2px;
    background-color: #d13447;
    top: -10%;
    left: 36%;
    opacity: 0.621212729;
    transform: rotate(214.2096914354deg);
    -webkit-animation: drop-48 4.1496008193s 0.7340213088s infinite;
            animation: drop-48 4.1496008193s 0.7340213088s infinite;
  }
  
  @-webkit-keyframes drop-48 {
    100% {
      top: 110%;
      left: 45%;
    }
  }
  
  @keyframes drop-48 {
    100% {
      top: 110%;
      left: 45%;
    }
  }
  .confetti-49 {
    width: 2px;
    height: 0.8px;
    background-color: #263672;
    top: -10%;
    left: 39%;
    opacity: 1.2547037912;
    transform: rotate(13.3169078936deg);
    -webkit-animation: drop-49 4.7674036083s 0.0473098462s infinite;
            animation: drop-49 4.7674036083s 0.0473098462s infinite;
  }
  
  @-webkit-keyframes drop-49 {
    100% {
      top: 110%;
      left: 48%;
    }
  }
  
  @keyframes drop-49 {
    100% {
      top: 110%;
      left: 48%;
    }
  }
  .confetti-50 {
    width: 2px;
    height: 0.8px;
    background-color: #d13447;
    top: -10%;
    left: 18%;
    opacity: 0.9811549582;
    transform: rotate(229.737376155deg);
    -webkit-animation: drop-50 4.3136188426s 0.7265211693s infinite;
            animation: drop-50 4.3136188426s 0.7265211693s infinite;
  }
  
  @-webkit-keyframes drop-50 {
    100% {
      top: 110%;
      left: 20%;
    }
  }
  
  @keyframes drop-50 {
    100% {
      top: 110%;
      left: 20%;
    }
  }
  .confetti-51 {
    width: 6px;
    height: 2.4px;
    background-color: #d13447;
    top: -10%;
    left: 26%;
    opacity: 1.4774318571;
    transform: rotate(72.5204732597deg);
    -webkit-animation: drop-51 4.5890459266s 0.6206941883s infinite;
            animation: drop-51 4.5890459266s 0.6206941883s infinite;
  }
  
  @-webkit-keyframes drop-51 {
    100% {
      top: 110%;
      left: 41%;
    }
  }
  
  @keyframes drop-51 {
    100% {
      top: 110%;
      left: 41%;
    }
  }
  .confetti-52 {
    width: 3px;
    height: 1.2px;
    background-color: #ffbf00;
    top: -10%;
    left: 33%;
    opacity: 1.2134690341;
    transform: rotate(331.6570756196deg);
    -webkit-animation: drop-52 4.2975580773s 0.4935632766s infinite;
            animation: drop-52 4.2975580773s 0.4935632766s infinite;
  }
  
  @-webkit-keyframes drop-52 {
    100% {
      top: 110%;
      left: 36%;
    }
  }
  
  @keyframes drop-52 {
    100% {
      top: 110%;
      left: 36%;
    }
  }
  .confetti-53 {
    width: 4px;
    height: 1.6px;
    background-color: #ffbf00;
    top: -10%;
    left: 10%;
    opacity: 0.5721218047;
    transform: rotate(247.8246909572deg);
    -webkit-animation: drop-53 4.9723983233s 0.8595436313s infinite;
            animation: drop-53 4.9723983233s 0.8595436313s infinite;
  }
  
  @-webkit-keyframes drop-53 {
    100% {
      top: 110%;
      left: 19%;
    }
  }
  
  @keyframes drop-53 {
    100% {
      top: 110%;
      left: 19%;
    }
  }
  .confetti-54 {
    width: 8px;
    height: 3.2px;
    background-color: #d13447;
    top: -10%;
    left: 47%;
    opacity: 1.4912025924;
    transform: rotate(129.4043761765deg);
    -webkit-animation: drop-54 4.6347427386s 0.7329449979s infinite;
            animation: drop-54 4.6347427386s 0.7329449979s infinite;
  }
  
  @-webkit-keyframes drop-54 {
    100% {
      top: 110%;
      left: 54%;
    }
  }
  
  @keyframes drop-54 {
    100% {
      top: 110%;
      left: 54%;
    }
  }
  .confetti-55 {
    width: 5px;
    height: 2px;
    background-color: #ffbf00;
    top: -10%;
    left: 8%;
    opacity: 0.7638151963;
    transform: rotate(353.4126754245deg);
    -webkit-animation: drop-55 4.8152031193s 0.0731013088s infinite;
            animation: drop-55 4.8152031193s 0.0731013088s infinite;
  }
  
  @-webkit-keyframes drop-55 {
    100% {
      top: 110%;
      left: 12%;
    }
  }
  
  @keyframes drop-55 {
    100% {
      top: 110%;
      left: 12%;
    }
  }
  .confetti-56 {
    width: 1px;
    height: 0.4px;
    background-color: #ffbf00;
    top: -10%;
    left: 63%;
    opacity: 0.9475972795;
    transform: rotate(87.6168163293deg);
    -webkit-animation: drop-56 4.134663991s 0.5768073274s infinite;
            animation: drop-56 4.134663991s 0.5768073274s infinite;
  }
  
  @-webkit-keyframes drop-56 {
    100% {
      top: 110%;
      left: 65%;
    }
  }
  
  @keyframes drop-56 {
    100% {
      top: 110%;
      left: 65%;
    }
  }
  .confetti-57 {
    width: 5px;
    height: 2px;
    background-color: #ffbf00;
    top: -10%;
    left: 63%;
    opacity: 0.5342336245;
    transform: rotate(132.9353406397deg);
    -webkit-animation: drop-57 4.3211578152s 0.8905537051s infinite;
            animation: drop-57 4.3211578152s 0.8905537051s infinite;
  }
  
  @-webkit-keyframes drop-57 {
    100% {
      top: 110%;
      left: 67%;
    }
  }
  
  @keyframes drop-57 {
    100% {
      top: 110%;
      left: 67%;
    }
  }
  .confetti-58 {
    width: 8px;
    height: 3.2px;
    background-color: #ffbf00;
    top: -10%;
    left: 50%;
    opacity: 1.364014162;
    transform: rotate(91.1316328077deg);
    -webkit-animation: drop-58 4.9258655836s 0.4617800545s infinite;
            animation: drop-58 4.9258655836s 0.4617800545s infinite;
  }
  
  @-webkit-keyframes drop-58 {
    100% {
      top: 110%;
      left: 51%;
    }
  }
  
  @keyframes drop-58 {
    100% {
      top: 110%;
      left: 51%;
    }
  }
  .confetti-59 {
    width: 4px;
    height: 1.6px;
    background-color: #ffbf00;
    top: -10%;
    left: 27%;
    opacity: 1.1803759647;
    transform: rotate(246.9669629981deg);
    -webkit-animation: drop-59 4.8110770947s 0.3024975272s infinite;
            animation: drop-59 4.8110770947s 0.3024975272s infinite;
  }
  
  @-webkit-keyframes drop-59 {
    100% {
      top: 110%;
      left: 41%;
    }
  }
  
  @keyframes drop-59 {
    100% {
      top: 110%;
      left: 41%;
    }
  }
  .confetti-60 {
    width: 6px;
    height: 2.4px;
    background-color: #263672;
    top: -10%;
    left: 80%;
    opacity: 1.4561044325;
    transform: rotate(266.9439425952deg);
    -webkit-animation: drop-60 4.5562023853s 0.2626081037s infinite;
            animation: drop-60 4.5562023853s 0.2626081037s infinite;
  }
  
  @-webkit-keyframes drop-60 {
    100% {
      top: 110%;
      left: 85%;
    }
  }
  
  @keyframes drop-60 {
    100% {
      top: 110%;
      left: 85%;
    }
  }
  .confetti-61 {
    width: 3px;
    height: 1.2px;
    background-color: #ffbf00;
    top: -10%;
    left: 2%;
    opacity: 0.6469775542;
    transform: rotate(142.4886778179deg);
    -webkit-animation: drop-61 4.3444887515s 0.4811830848s infinite;
            animation: drop-61 4.3444887515s 0.4811830848s infinite;
  }
  
  @-webkit-keyframes drop-61 {
    100% {
      top: 110%;
      left: 4%;
    }
  }
  
  @keyframes drop-61 {
    100% {
      top: 110%;
      left: 4%;
    }
  }
  .confetti-62 {
    width: 6px;
    height: 2.4px;
    background-color: #d13447;
    top: -10%;
    left: 100%;
    opacity: 1.1010185592;
    transform: rotate(123.7196155796deg);
    -webkit-animation: drop-62 4.3979323711s 0.1850789922s infinite;
            animation: drop-62 4.3979323711s 0.1850789922s infinite;
  }
  
  @-webkit-keyframes drop-62 {
    100% {
      top: 110%;
      left: 102%;
    }
  }
  
  @keyframes drop-62 {
    100% {
      top: 110%;
      left: 102%;
    }
  }
  .confetti-63 {
    width: 5px;
    height: 2px;
    background-color: #d13447;
    top: -10%;
    left: 35%;
    opacity: 1.4607069977;
    transform: rotate(53.8916671397deg);
    -webkit-animation: drop-63 4.0782078242s 0.8555478944s infinite;
            animation: drop-63 4.0782078242s 0.8555478944s infinite;
  }
  
  @-webkit-keyframes drop-63 {
    100% {
      top: 110%;
      left: 41%;
    }
  }
  
  @keyframes drop-63 {
    100% {
      top: 110%;
      left: 41%;
    }
  }
  .confetti-64 {
    width: 3px;
    height: 1.2px;
    background-color: #263672;
    top: -10%;
    left: 54%;
    opacity: 1.0081486833;
    transform: rotate(3.4309270492deg);
    -webkit-animation: drop-64 4.583640466s 0.9739703454s infinite;
            animation: drop-64 4.583640466s 0.9739703454s infinite;
  }
  
  @-webkit-keyframes drop-64 {
    100% {
      top: 110%;
      left: 65%;
    }
  }
  
  @keyframes drop-64 {
    100% {
      top: 110%;
      left: 65%;
    }
  }
  .confetti-65 {
    width: 6px;
    height: 2.4px;
    background-color: #d13447;
    top: -10%;
    left: 78%;
    opacity: 1.4134930174;
    transform: rotate(49.4137067308deg);
    -webkit-animation: drop-65 4.2819725884s 0.3197754895s infinite;
            animation: drop-65 4.2819725884s 0.3197754895s infinite;
  }
  
  @-webkit-keyframes drop-65 {
    100% {
      top: 110%;
      left: 81%;
    }
  }
  
  @keyframes drop-65 {
    100% {
      top: 110%;
      left: 81%;
    }
  }
  .confetti-66 {
    width: 6px;
    height: 2.4px;
    background-color: #d13447;
    top: -10%;
    left: 43%;
    opacity: 0.8923769044;
    transform: rotate(272.4767617268deg);
    -webkit-animation: drop-66 4.0505195453s 0.5497693129s infinite;
            animation: drop-66 4.0505195453s 0.5497693129s infinite;
  }
  
  @-webkit-keyframes drop-66 {
    100% {
      top: 110%;
      left: 52%;
    }
  }
  
  @keyframes drop-66 {
    100% {
      top: 110%;
      left: 52%;
    }
  }
  .confetti-67 {
    width: 2px;
    height: 0.8px;
    background-color: #263672;
    top: -10%;
    left: 2%;
    opacity: 1.4963391755;
    transform: rotate(137.153942557deg);
    -webkit-animation: drop-67 4.7221628606s 0.9628362875s infinite;
            animation: drop-67 4.7221628606s 0.9628362875s infinite;
  }
  
  @-webkit-keyframes drop-67 {
    100% {
      top: 110%;
      left: 5%;
    }
  }
  
  @keyframes drop-67 {
    100% {
      top: 110%;
      left: 5%;
    }
  }
  .confetti-68 {
    width: 2px;
    height: 0.8px;
    background-color: #ffbf00;
    top: -10%;
    left: 31%;
    opacity: 0.5560586847;
    transform: rotate(228.8809181293deg);
    -webkit-animation: drop-68 4.9511467665s 0.0404496621s infinite;
            animation: drop-68 4.9511467665s 0.0404496621s infinite;
  }
  
  @-webkit-keyframes drop-68 {
    100% {
      top: 110%;
      left: 38%;
    }
  }
  
  @keyframes drop-68 {
    100% {
      top: 110%;
      left: 38%;
    }
  }
  .confetti-69 {
    width: 5px;
    height: 2px;
    background-color: #d13447;
    top: -10%;
    left: 79%;
    opacity: 1.4262679618;
    transform: rotate(178.0503017877deg);
    -webkit-animation: drop-69 4.4975291401s 0.4744614925s infinite;
            animation: drop-69 4.4975291401s 0.4744614925s infinite;
  }
  
  @-webkit-keyframes drop-69 {
    100% {
      top: 110%;
      left: 88%;
    }
  }
  
  @keyframes drop-69 {
    100% {
      top: 110%;
      left: 88%;
    }
  }
  .confetti-70 {
    width: 7px;
    height: 2.8px;
    background-color: #d13447;
    top: -10%;
    left: 88%;
    opacity: 0.8516029994;
    transform: rotate(326.9898981989deg);
    -webkit-animation: drop-70 4.3763741045s 0.50558794s infinite;
            animation: drop-70 4.3763741045s 0.50558794s infinite;
  }
  
  @-webkit-keyframes drop-70 {
    100% {
      top: 110%;
      left: 98%;
    }
  }
  
  @keyframes drop-70 {
    100% {
      top: 110%;
      left: 98%;
    }
  }
  .confetti-71 {
    width: 1px;
    height: 0.4px;
    background-color: #d13447;
    top: -10%;
    left: 14%;
    opacity: 1.4270283286;
    transform: rotate(258.3218496952deg);
    -webkit-animation: drop-71 4.0009864991s 0.9845154639s infinite;
            animation: drop-71 4.0009864991s 0.9845154639s infinite;
  }
  
  @-webkit-keyframes drop-71 {
    100% {
      top: 110%;
      left: 27%;
    }
  }
  
  @keyframes drop-71 {
    100% {
      top: 110%;
      left: 27%;
    }
  }
  .confetti-72 {
    width: 7px;
    height: 2.8px;
    background-color: #ffbf00;
    top: -10%;
    left: 74%;
    opacity: 0.8894801045;
    transform: rotate(133.6598675916deg);
    -webkit-animation: drop-72 4.5571593876s 0.3208541291s infinite;
            animation: drop-72 4.5571593876s 0.3208541291s infinite;
  }
  
  @-webkit-keyframes drop-72 {
    100% {
      top: 110%;
      left: 87%;
    }
  }
  
  @keyframes drop-72 {
    100% {
      top: 110%;
      left: 87%;
    }
  }
  .confetti-73 {
    width: 2px;
    height: 0.8px;
    background-color: #263672;
    top: -10%;
    left: 11%;
    opacity: 0.9752794141;
    transform: rotate(310.9801802181deg);
    -webkit-animation: drop-73 4.7243930241s 0.6970331474s infinite;
            animation: drop-73 4.7243930241s 0.6970331474s infinite;
  }
  
  @-webkit-keyframes drop-73 {
    100% {
      top: 110%;
      left: 12%;
    }
  }
  
  @keyframes drop-73 {
    100% {
      top: 110%;
      left: 12%;
    }
  }
  .confetti-74 {
    width: 6px;
    height: 2.4px;
    background-color: #ffbf00;
    top: -10%;
    left: 82%;
    opacity: 1.2495944573;
    transform: rotate(332.0416494616deg);
    -webkit-animation: drop-74 4.8845506541s 0.9607303122s infinite;
            animation: drop-74 4.8845506541s 0.9607303122s infinite;
  }
  
  @-webkit-keyframes drop-74 {
    100% {
      top: 110%;
      left: 92%;
    }
  }
  
  @keyframes drop-74 {
    100% {
      top: 110%;
      left: 92%;
    }
  }
  .confetti-75 {
    width: 7px;
    height: 2.8px;
    background-color: #d13447;
    top: -10%;
    left: 81%;
    opacity: 1.0602438521;
    transform: rotate(135.6980951516deg);
    -webkit-animation: drop-75 4.4732437833s 0.5209873449s infinite;
            animation: drop-75 4.4732437833s 0.5209873449s infinite;
  }
  
  @-webkit-keyframes drop-75 {
    100% {
      top: 110%;
      left: 83%;
    }
  }
  
  @keyframes drop-75 {
    100% {
      top: 110%;
      left: 83%;
    }
  }
  .confetti-76 {
    width: 2px;
    height: 0.8px;
    background-color: #d13447;
    top: -10%;
    left: 93%;
    opacity: 0.601773605;
    transform: rotate(205.7121519899deg);
    -webkit-animation: drop-76 4.9547882658s 0.6462112383s infinite;
            animation: drop-76 4.9547882658s 0.6462112383s infinite;
  }
  
  @-webkit-keyframes drop-76 {
    100% {
      top: 110%;
      left: 99%;
    }
  }
  
  @keyframes drop-76 {
    100% {
      top: 110%;
      left: 99%;
    }
  }
  .confetti-77 {
    width: 6px;
    height: 2.4px;
    background-color: #263672;
    top: -10%;
    left: 30%;
    opacity: 1.4476599958;
    transform: rotate(160.9835338541deg);
    -webkit-animation: drop-77 4.3088270202s 0.5275743876s infinite;
            animation: drop-77 4.3088270202s 0.5275743876s infinite;
  }
  
  @-webkit-keyframes drop-77 {
    100% {
      top: 110%;
      left: 42%;
    }
  }
  
  @keyframes drop-77 {
    100% {
      top: 110%;
      left: 42%;
    }
  }
  .confetti-78 {
    width: 7px;
    height: 2.8px;
    background-color: #d13447;
    top: -10%;
    left: 52%;
    opacity: 1.0149544445;
    transform: rotate(30.8322586103deg);
    -webkit-animation: drop-78 4.8472239711s 0.8317394633s infinite;
            animation: drop-78 4.8472239711s 0.8317394633s infinite;
  }
  
  @-webkit-keyframes drop-78 {
    100% {
      top: 110%;
      left: 61%;
    }
  }
  
  @keyframes drop-78 {
    100% {
      top: 110%;
      left: 61%;
    }
  }
  .confetti-79 {
    width: 5px;
    height: 2px;
    background-color: #263672;
    top: -10%;
    left: 2%;
    opacity: 1.2186327355;
    transform: rotate(242.177032937deg);
    -webkit-animation: drop-79 4.4262726861s 0.5936042475s infinite;
            animation: drop-79 4.4262726861s 0.5936042475s infinite;
  }
  
  @-webkit-keyframes drop-79 {
    100% {
      top: 110%;
      left: 4%;
    }
  }
  
  @keyframes drop-79 {
    100% {
      top: 110%;
      left: 4%;
    }
  }
  .confetti-80 {
    width: 1px;
    height: 0.4px;
    background-color: #ffbf00;
    top: -10%;
    left: 34%;
    opacity: 1.2489285389;
    transform: rotate(327.6705741212deg);
    -webkit-animation: drop-80 4.6023014544s 0.1900279837s infinite;
            animation: drop-80 4.6023014544s 0.1900279837s infinite;
  }
  
  @-webkit-keyframes drop-80 {
    100% {
      top: 110%;
      left: 43%;
    }
  }
  
  @keyframes drop-80 {
    100% {
      top: 110%;
      left: 43%;
    }
  }
  .confetti-81 {
    width: 6px;
    height: 2.4px;
    background-color: #ffbf00;
    top: -10%;
    left: 39%;
    opacity: 0.844399643;
    transform: rotate(226.5004328089deg);
    -webkit-animation: drop-81 4.8591427965s 0.9258070699s infinite;
            animation: drop-81 4.8591427965s 0.9258070699s infinite;
  }
  
  @-webkit-keyframes drop-81 {
    100% {
      top: 110%;
      left: 54%;
    }
  }
  
  @keyframes drop-81 {
    100% {
      top: 110%;
      left: 54%;
    }
  }
  .confetti-82 {
    width: 6px;
    height: 2.4px;
    background-color: #263672;
    top: -10%;
    left: 97%;
    opacity: 1.1586060745;
    transform: rotate(30.489037481deg);
    -webkit-animation: drop-82 4.9627546422s 0.6611124626s infinite;
            animation: drop-82 4.9627546422s 0.6611124626s infinite;
  }
  
  @-webkit-keyframes drop-82 {
    100% {
      top: 110%;
      left: 102%;
    }
  }
  
  @keyframes drop-82 {
    100% {
      top: 110%;
      left: 102%;
    }
  }
  .confetti-83 {
    width: 3px;
    height: 1.2px;
    background-color: #ffbf00;
    top: -10%;
    left: 82%;
    opacity: 0.5708520669;
    transform: rotate(51.8395633589deg);
    -webkit-animation: drop-83 4.6508048253s 0.6740865926s infinite;
            animation: drop-83 4.6508048253s 0.6740865926s infinite;
  }
  
  @-webkit-keyframes drop-83 {
    100% {
      top: 110%;
      left: 89%;
    }
  }
  
  @keyframes drop-83 {
    100% {
      top: 110%;
      left: 89%;
    }
  }
  .confetti-84 {
    width: 3px;
    height: 1.2px;
    background-color: #ffbf00;
    top: -10%;
    left: 47%;
    opacity: 0.9985695676;
    transform: rotate(159.5136144846deg);
    -webkit-animation: drop-84 4.4446883793s 0.4395429419s infinite;
            animation: drop-84 4.4446883793s 0.4395429419s infinite;
  }
  
  @-webkit-keyframes drop-84 {
    100% {
      top: 110%;
      left: 53%;
    }
  }
  
  @keyframes drop-84 {
    100% {
      top: 110%;
      left: 53%;
    }
  }
  .confetti-85 {
    width: 8px;
    height: 3.2px;
    background-color: #d13447;
    top: -10%;
    left: 5%;
    opacity: 1.3709022371;
    transform: rotate(322.9070760469deg);
    -webkit-animation: drop-85 4.693794196s 0.1461486152s infinite;
            animation: drop-85 4.693794196s 0.1461486152s infinite;
  }
  
  @-webkit-keyframes drop-85 {
    100% {
      top: 110%;
      left: 20%;
    }
  }
  
  @keyframes drop-85 {
    100% {
      top: 110%;
      left: 20%;
    }
  }
  .confetti-86 {
    width: 7px;
    height: 2.8px;
    background-color: #263672;
    top: -10%;
    left: 27%;
    opacity: 1.4343877537;
    transform: rotate(335.1440280977deg);
    -webkit-animation: drop-86 4.6713404258s 0.368606938s infinite;
            animation: drop-86 4.6713404258s 0.368606938s infinite;
  }
  
  @-webkit-keyframes drop-86 {
    100% {
      top: 110%;
      left: 31%;
    }
  }
  
  @keyframes drop-86 {
    100% {
      top: 110%;
      left: 31%;
    }
  }
  .confetti-87 {
    width: 3px;
    height: 1.2px;
    background-color: #263672;
    top: -10%;
    left: 37%;
    opacity: 0.6819814756;
    transform: rotate(187.0712508629deg);
    -webkit-animation: drop-87 4.9844943693s 0.9267482256s infinite;
            animation: drop-87 4.9844943693s 0.9267482256s infinite;
  }
  
  @-webkit-keyframes drop-87 {
    100% {
      top: 110%;
      left: 38%;
    }
  }
  
  @keyframes drop-87 {
    100% {
      top: 110%;
      left: 38%;
    }
  }
  .confetti-88 {
    width: 5px;
    height: 2px;
    background-color: #d13447;
    top: -10%;
    left: 60%;
    opacity: 0.7119530785;
    transform: rotate(311.2880739836deg);
    -webkit-animation: drop-88 4.1899087259s 0.0818864076s infinite;
            animation: drop-88 4.1899087259s 0.0818864076s infinite;
  }
  
  @-webkit-keyframes drop-88 {
    100% {
      top: 110%;
      left: 70%;
    }
  }
  
  @keyframes drop-88 {
    100% {
      top: 110%;
      left: 70%;
    }
  }
  .confetti-89 {
    width: 7px;
    height: 2.8px;
    background-color: #ffbf00;
    top: -10%;
    left: 34%;
    opacity: 0.9880418982;
    transform: rotate(211.5249375931deg);
    -webkit-animation: drop-89 4.7676152206s 0.72840618s infinite;
            animation: drop-89 4.7676152206s 0.72840618s infinite;
  }
  
  @-webkit-keyframes drop-89 {
    100% {
      top: 110%;
      left: 36%;
    }
  }
  
  @keyframes drop-89 {
    100% {
      top: 110%;
      left: 36%;
    }
  }
  .confetti-90 {
    width: 2px;
    height: 0.8px;
    background-color: #ffbf00;
    top: -10%;
    left: 31%;
    opacity: 0.5510213508;
    transform: rotate(60.8524837861deg);
    -webkit-animation: drop-90 4.7345954898s 0.3499293099s infinite;
            animation: drop-90 4.7345954898s 0.3499293099s infinite;
  }
  
  @-webkit-keyframes drop-90 {
    100% {
      top: 110%;
      left: 37%;
    }
  }
  
  @keyframes drop-90 {
    100% {
      top: 110%;
      left: 37%;
    }
  }
  .confetti-91 {
    width: 2px;
    height: 0.8px;
    background-color: #ffbf00;
    top: -10%;
    left: 4%;
    opacity: 1.3020202577;
    transform: rotate(53.7860667945deg);
    -webkit-animation: drop-91 4.6522691611s 0.0568308301s infinite;
            animation: drop-91 4.6522691611s 0.0568308301s infinite;
  }
  
  @-webkit-keyframes drop-91 {
    100% {
      top: 110%;
      left: 5%;
    }
  }
  
  @keyframes drop-91 {
    100% {
      top: 110%;
      left: 5%;
    }
  }
  .confetti-92 {
    width: 4px;
    height: 1.6px;
    background-color: #d13447;
    top: -10%;
    left: 73%;
    opacity: 1.1382393501;
    transform: rotate(231.8439754665deg);
    -webkit-animation: drop-92 4.4381533226s 0.1119781473s infinite;
            animation: drop-92 4.4381533226s 0.1119781473s infinite;
  }
  
  @-webkit-keyframes drop-92 {
    100% {
      top: 110%;
      left: 86%;
    }
  }
  
  @keyframes drop-92 {
    100% {
      top: 110%;
      left: 86%;
    }
  }
  .confetti-93 {
    width: 8px;
    height: 3.2px;
    background-color: #ffbf00;
    top: -10%;
    left: 25%;
    opacity: 1.4061884069;
    transform: rotate(218.5952695913deg);
    -webkit-animation: drop-93 4.5123305319s 0.6675138114s infinite;
            animation: drop-93 4.5123305319s 0.6675138114s infinite;
  }
  
  @-webkit-keyframes drop-93 {
    100% {
      top: 110%;
      left: 34%;
    }
  }
  
  @keyframes drop-93 {
    100% {
      top: 110%;
      left: 34%;
    }
  }
  .confetti-94 {
    width: 6px;
    height: 2.4px;
    background-color: #ffbf00;
    top: -10%;
    left: 50%;
    opacity: 0.9612359475;
    transform: rotate(73.9385079405deg);
    -webkit-animation: drop-94 4.7838643241s 0.4220689015s infinite;
            animation: drop-94 4.7838643241s 0.4220689015s infinite;
  }
  
  @-webkit-keyframes drop-94 {
    100% {
      top: 110%;
      left: 65%;
    }
  }
  
  @keyframes drop-94 {
    100% {
      top: 110%;
      left: 65%;
    }
  }
  .confetti-95 {
    width: 5px;
    height: 2px;
    background-color: #ffbf00;
    top: -10%;
    left: 36%;
    opacity: 0.6571079267;
    transform: rotate(105.6990767408deg);
    -webkit-animation: drop-95 4.1510913697s 0.8095352215s infinite;
            animation: drop-95 4.1510913697s 0.8095352215s infinite;
  }
  
  @-webkit-keyframes drop-95 {
    100% {
      top: 110%;
      left: 40%;
    }
  }
  
  @keyframes drop-95 {
    100% {
      top: 110%;
      left: 40%;
    }
  }
  .confetti-96 {
    width: 1px;
    height: 0.4px;
    background-color: #263672;
    top: -10%;
    left: 64%;
    opacity: 0.5807454642;
    transform: rotate(303.7800079752deg);
    -webkit-animation: drop-96 4.5583731245s 0.6534129315s infinite;
            animation: drop-96 4.5583731245s 0.6534129315s infinite;
  }
  
  @-webkit-keyframes drop-96 {
    100% {
      top: 110%;
      left: 67%;
    }
  }
  
  @keyframes drop-96 {
    100% {
      top: 110%;
      left: 67%;
    }
  }
  .confetti-97 {
    width: 4px;
    height: 1.6px;
    background-color: #263672;
    top: -10%;
    left: 13%;
    opacity: 0.634789474;
    transform: rotate(85.4744413323deg);
    -webkit-animation: drop-97 4.0589215836s 0.2598804763s infinite;
            animation: drop-97 4.0589215836s 0.2598804763s infinite;
  }
  
  @-webkit-keyframes drop-97 {
    100% {
      top: 110%;
      left: 14%;
    }
  }
  
  @keyframes drop-97 {
    100% {
      top: 110%;
      left: 14%;
    }
  }
  .confetti-98 {
    width: 4px;
    height: 1.6px;
    background-color: #263672;
    top: -10%;
    left: 36%;
    opacity: 0.8872060262;
    transform: rotate(235.8079367607deg);
    -webkit-animation: drop-98 4.9257224986s 0.0554075565s infinite;
            animation: drop-98 4.9257224986s 0.0554075565s infinite;
  }
  
  @-webkit-keyframes drop-98 {
    100% {
      top: 110%;
      left: 38%;
    }
  }
  
  @keyframes drop-98 {
    100% {
      top: 110%;
      left: 38%;
    }
  }
  .confetti-99 {
    width: 6px;
    height: 2.4px;
    background-color: #d13447;
    top: -10%;
    left: 34%;
    opacity: 0.7560745022;
    transform: rotate(249.573707748deg);
    -webkit-animation: drop-99 4.5551467533s 0.0301083591s infinite;
            animation: drop-99 4.5551467533s 0.0301083591s infinite;
  }
  
  @-webkit-keyframes drop-99 {
    100% {
      top: 110%;
      left: 35%;
    }
  }
  
  @keyframes drop-99 {
    100% {
      top: 110%;
      left: 35%;
    }
  }
  .confetti-100 {
    width: 7px;
    height: 2.8px;
    background-color: #d13447;
    top: -10%;
    left: 27%;
    opacity: 0.8833610353;
    transform: rotate(86.6675485537deg);
    -webkit-animation: drop-100 4.4151749861s 0.0103499095s infinite;
            animation: drop-100 4.4151749861s 0.0103499095s infinite;
  }
  
  @-webkit-keyframes drop-100 {
    100% {
      top: 110%;
      left: 34%;
    }
  }
  
  @keyframes drop-100 {
    100% {
      top: 110%;
      left: 34%;
    }
  }
  .confetti-101 {
    width: 1px;
    height: 0.4px;
    background-color: #ffbf00;
    top: -10%;
    left: 16%;
    opacity: 1.1216196357;
    transform: rotate(112.3047910161deg);
    -webkit-animation: drop-101 4.904866602s 0.1608971959s infinite;
            animation: drop-101 4.904866602s 0.1608971959s infinite;
  }
  
  @-webkit-keyframes drop-101 {
    100% {
      top: 110%;
      left: 24%;
    }
  }
  
  @keyframes drop-101 {
    100% {
      top: 110%;
      left: 24%;
    }
  }
  .confetti-102 {
    width: 2px;
    height: 0.8px;
    background-color: #ffbf00;
    top: -10%;
    left: 16%;
    opacity: 0.5977228952;
    transform: rotate(222.1552299088deg);
    -webkit-animation: drop-102 4.1578238814s 0.3780189554s infinite;
            animation: drop-102 4.1578238814s 0.3780189554s infinite;
  }
  
  @-webkit-keyframes drop-102 {
    100% {
      top: 110%;
      left: 20%;
    }
  }
  
  @keyframes drop-102 {
    100% {
      top: 110%;
      left: 20%;
    }
  }
  .confetti-103 {
    width: 8px;
    height: 3.2px;
    background-color: #d13447;
    top: -10%;
    left: 19%;
    opacity: 0.7073594431;
    transform: rotate(223.528815625deg);
    -webkit-animation: drop-103 4.2288310013s 0.9595730686s infinite;
            animation: drop-103 4.2288310013s 0.9595730686s infinite;
  }
  
  @-webkit-keyframes drop-103 {
    100% {
      top: 110%;
      left: 27%;
    }
  }
  
  @keyframes drop-103 {
    100% {
      top: 110%;
      left: 27%;
    }
  }
  .confetti-104 {
    width: 3px;
    height: 1.2px;
    background-color: #ffbf00;
    top: -10%;
    left: 61%;
    opacity: 1.1208531596;
    transform: rotate(112.341966375deg);
    -webkit-animation: drop-104 4.0340459168s 0.7175431181s infinite;
            animation: drop-104 4.0340459168s 0.7175431181s infinite;
  }
  
  @-webkit-keyframes drop-104 {
    100% {
      top: 110%;
      left: 62%;
    }
  }
  
  @keyframes drop-104 {
    100% {
      top: 110%;
      left: 62%;
    }
  }
  .confetti-105 {
    width: 4px;
    height: 1.6px;
    background-color: #ffbf00;
    top: -10%;
    left: 33%;
    opacity: 0.9048647195;
    transform: rotate(353.092906522deg);
    -webkit-animation: drop-105 4.4131994198s 0.2144128849s infinite;
            animation: drop-105 4.4131994198s 0.2144128849s infinite;
  }
  
  @-webkit-keyframes drop-105 {
    100% {
      top: 110%;
      left: 35%;
    }
  }
  
  @keyframes drop-105 {
    100% {
      top: 110%;
      left: 35%;
    }
  }
  .confetti-106 {
    width: 6px;
    height: 2.4px;
    background-color: #d13447;
    top: -10%;
    left: 19%;
    opacity: 0.5121438593;
    transform: rotate(187.1820761813deg);
    -webkit-animation: drop-106 4.2705291227s 0.0521090986s infinite;
            animation: drop-106 4.2705291227s 0.0521090986s infinite;
  }
  
  @-webkit-keyframes drop-106 {
    100% {
      top: 110%;
      left: 32%;
    }
  }
  
  @keyframes drop-106 {
    100% {
      top: 110%;
      left: 32%;
    }
  }
  .confetti-107 {
    width: 1px;
    height: 0.4px;
    background-color: #d13447;
    top: -10%;
    left: 55%;
    opacity: 1.2132828804;
    transform: rotate(211.2904960338deg);
    -webkit-animation: drop-107 4.5124187041s 0.3123226588s infinite;
            animation: drop-107 4.5124187041s 0.3123226588s infinite;
  }
  
  @-webkit-keyframes drop-107 {
    100% {
      top: 110%;
      left: 70%;
    }
  }
  
  @keyframes drop-107 {
    100% {
      top: 110%;
      left: 70%;
    }
  }
  .confetti-108 {
    width: 5px;
    height: 2px;
    background-color: #ffbf00;
    top: -10%;
    left: 84%;
    opacity: 0.5132797978;
    transform: rotate(56.1427355484deg);
    -webkit-animation: drop-108 4.596004216s 0.4627735359s infinite;
            animation: drop-108 4.596004216s 0.4627735359s infinite;
  }
  
  @-webkit-keyframes drop-108 {
    100% {
      top: 110%;
      left: 92%;
    }
  }
  
  @keyframes drop-108 {
    100% {
      top: 110%;
      left: 92%;
    }
  }
  .confetti-109 {
    width: 6px;
    height: 2.4px;
    background-color: #263672;
    top: -10%;
    left: 1%;
    opacity: 1.1104330658;
    transform: rotate(203.8930650782deg);
    -webkit-animation: drop-109 4.2521146891s 0.731192162s infinite;
            animation: drop-109 4.2521146891s 0.731192162s infinite;
  }
  
  @-webkit-keyframes drop-109 {
    100% {
      top: 110%;
      left: 2%;
    }
  }
  
  @keyframes drop-109 {
    100% {
      top: 110%;
      left: 2%;
    }
  }
  .confetti-110 {
    width: 8px;
    height: 3.2px;
    background-color: #d13447;
    top: -10%;
    left: 12%;
    opacity: 0.8824818795;
    transform: rotate(343.2198216716deg);
    -webkit-animation: drop-110 4.5604471141s 0.9697153999s infinite;
            animation: drop-110 4.5604471141s 0.9697153999s infinite;
  }
  
  @-webkit-keyframes drop-110 {
    100% {
      top: 110%;
      left: 24%;
    }
  }
  
  @keyframes drop-110 {
    100% {
      top: 110%;
      left: 24%;
    }
  }
  .confetti-111 {
    width: 1px;
    height: 0.4px;
    background-color: #ffbf00;
    top: -10%;
    left: 90%;
    opacity: 1.0238446312;
    transform: rotate(262.2312369725deg);
    -webkit-animation: drop-111 4.0857998326s 0.7529532236s infinite;
            animation: drop-111 4.0857998326s 0.7529532236s infinite;
  }
  
  @-webkit-keyframes drop-111 {
    100% {
      top: 110%;
      left: 97%;
    }
  }
  
  @keyframes drop-111 {
    100% {
      top: 110%;
      left: 97%;
    }
  }
  .confetti-112 {
    width: 7px;
    height: 2.8px;
    background-color: #263672;
    top: -10%;
    left: 34%;
    opacity: 0.8673194096;
    transform: rotate(61.0114403689deg);
    -webkit-animation: drop-112 4.9388811016s 0.5406871221s infinite;
            animation: drop-112 4.9388811016s 0.5406871221s infinite;
  }
  
  @-webkit-keyframes drop-112 {
    100% {
      top: 110%;
      left: 44%;
    }
  }
  
  @keyframes drop-112 {
    100% {
      top: 110%;
      left: 44%;
    }
  }
  .confetti-113 {
    width: 3px;
    height: 1.2px;
    background-color: #263672;
    top: -10%;
    left: 83%;
    opacity: 1.4121337852;
    transform: rotate(273.5648011864deg);
    -webkit-animation: drop-113 4.1428258492s 0.0992497203s infinite;
            animation: drop-113 4.1428258492s 0.0992497203s infinite;
  }
  
  @-webkit-keyframes drop-113 {
    100% {
      top: 110%;
      left: 91%;
    }
  }
  
  @keyframes drop-113 {
    100% {
      top: 110%;
      left: 91%;
    }
  }
  .confetti-114 {
    width: 3px;
    height: 1.2px;
    background-color: #d13447;
    top: -10%;
    left: 8%;
    opacity: 0.582133014;
    transform: rotate(111.066895997deg);
    -webkit-animation: drop-114 4.0948089677s 0.8654289894s infinite;
            animation: drop-114 4.0948089677s 0.8654289894s infinite;
  }
  
  @-webkit-keyframes drop-114 {
    100% {
      top: 110%;
      left: 14%;
    }
  }
  
  @keyframes drop-114 {
    100% {
      top: 110%;
      left: 14%;
    }
  }
  .confetti-115 {
    width: 1px;
    height: 0.4px;
    background-color: #d13447;
    top: -10%;
    left: 66%;
    opacity: 0.9476328557;
    transform: rotate(293.0720910524deg);
    -webkit-animation: drop-115 4.4042125668s 0.6455977048s infinite;
            animation: drop-115 4.4042125668s 0.6455977048s infinite;
  }
  
  @-webkit-keyframes drop-115 {
    100% {
      top: 110%;
      left: 68%;
    }
  }
  
  @keyframes drop-115 {
    100% {
      top: 110%;
      left: 68%;
    }
  }
  .confetti-116 {
    width: 7px;
    height: 2.8px;
    background-color: #ffbf00;
    top: -10%;
    left: 24%;
    opacity: 1.4967737712;
    transform: rotate(154.2646216959deg);
    -webkit-animation: drop-116 4.4680318708s 0.5594454507s infinite;
            animation: drop-116 4.4680318708s 0.5594454507s infinite;
  }
  
  @-webkit-keyframes drop-116 {
    100% {
      top: 110%;
      left: 32%;
    }
  }
  
  @keyframes drop-116 {
    100% {
      top: 110%;
      left: 32%;
    }
  }
  .confetti-117 {
    width: 5px;
    height: 2px;
    background-color: #ffbf00;
    top: -10%;
    left: 21%;
    opacity: 1.2887524359;
    transform: rotate(125.2661595565deg);
    -webkit-animation: drop-117 4.0305951346s 0.9079059021s infinite;
            animation: drop-117 4.0305951346s 0.9079059021s infinite;
  }
  
  @-webkit-keyframes drop-117 {
    100% {
      top: 110%;
      left: 23%;
    }
  }
  
  @keyframes drop-117 {
    100% {
      top: 110%;
      left: 23%;
    }
  }
  .confetti-118 {
    width: 4px;
    height: 1.6px;
    background-color: #d13447;
    top: -10%;
    left: 48%;
    opacity: 1.4236589678;
    transform: rotate(307.3928743966deg);
    -webkit-animation: drop-118 4.8305168014s 0.0875947589s infinite;
            animation: drop-118 4.8305168014s 0.0875947589s infinite;
  }
  
  @-webkit-keyframes drop-118 {
    100% {
      top: 110%;
      left: 54%;
    }
  }
  
  @keyframes drop-118 {
    100% {
      top: 110%;
      left: 54%;
    }
  }
  .confetti-119 {
    width: 6px;
    height: 2.4px;
    background-color: #263672;
    top: -10%;
    left: 8%;
    opacity: 0.7767584795;
    transform: rotate(134.6335453604deg);
    -webkit-animation: drop-119 4.7923048528s 0.1056682534s infinite;
            animation: drop-119 4.7923048528s 0.1056682534s infinite;
  }
  
  @-webkit-keyframes drop-119 {
    100% {
      top: 110%;
      left: 9%;
    }
  }
  
  @keyframes drop-119 {
    100% {
      top: 110%;
      left: 9%;
    }
  }
  .confetti-120 {
    width: 7px;
    height: 2.8px;
    background-color: #263672;
    top: -10%;
    left: 30%;
    opacity: 1.3031251553;
    transform: rotate(281.0029329704deg);
    -webkit-animation: drop-120 4.7092005907s 0.6116285244s infinite;
            animation: drop-120 4.7092005907s 0.6116285244s infinite;
  }
  
  @-webkit-keyframes drop-120 {
    100% {
      top: 110%;
      left: 35%;
    }
  }
  
  @keyframes drop-120 {
    100% {
      top: 110%;
      left: 35%;
    }
  }
  .confetti-121 {
    width: 4px;
    height: 1.6px;
    background-color: #263672;
    top: -10%;
    left: 85%;
    opacity: 0.6389380894;
    transform: rotate(244.9319951963deg);
    -webkit-animation: drop-121 4.0324857859s 0.5894739854s infinite;
            animation: drop-121 4.0324857859s 0.5894739854s infinite;
  }
  
  @-webkit-keyframes drop-121 {
    100% {
      top: 110%;
      left: 96%;
    }
  }
  
  @keyframes drop-121 {
    100% {
      top: 110%;
      left: 96%;
    }
  }
  .confetti-122 {
    width: 8px;
    height: 3.2px;
    background-color: #263672;
    top: -10%;
    left: 61%;
    opacity: 1.0627853909;
    transform: rotate(67.7106765407deg);
    -webkit-animation: drop-122 4.4543549811s 0.647527919s infinite;
            animation: drop-122 4.4543549811s 0.647527919s infinite;
  }
  
  @-webkit-keyframes drop-122 {
    100% {
      top: 110%;
      left: 66%;
    }
  }
  
  @keyframes drop-122 {
    100% {
      top: 110%;
      left: 66%;
    }
  }
  .confetti-123 {
    width: 2px;
    height: 0.8px;
    background-color: #d13447;
    top: -10%;
    left: 60%;
    opacity: 0.6875642235;
    transform: rotate(88.0995276517deg);
    -webkit-animation: drop-123 4.7778984055s 0.8357563226s infinite;
            animation: drop-123 4.7778984055s 0.8357563226s infinite;
  }
  
  @-webkit-keyframes drop-123 {
    100% {
      top: 110%;
      left: 73%;
    }
  }
  
  @keyframes drop-123 {
    100% {
      top: 110%;
      left: 73%;
    }
  }
  .confetti-124 {
    width: 4px;
    height: 1.6px;
    background-color: #ffbf00;
    top: -10%;
    left: 24%;
    opacity: 1.0777638423;
    transform: rotate(17.8933004deg);
    -webkit-animation: drop-124 4.8313136452s 0.5974333587s infinite;
            animation: drop-124 4.8313136452s 0.5974333587s infinite;
  }
  
  @-webkit-keyframes drop-124 {
    100% {
      top: 110%;
      left: 39%;
    }
  }
  
  @keyframes drop-124 {
    100% {
      top: 110%;
      left: 39%;
    }
  }
  .confetti-125 {
    width: 6px;
    height: 2.4px;
    background-color: #d13447;
    top: -10%;
    left: 94%;
    opacity: 1.1062309712;
    transform: rotate(232.2285765861deg);
    -webkit-animation: drop-125 4.1776173205s 0.9306107044s infinite;
            animation: drop-125 4.1776173205s 0.9306107044s infinite;
  }
  
  @-webkit-keyframes drop-125 {
    100% {
      top: 110%;
      left: 96%;
    }
  }
  
  @keyframes drop-125 {
    100% {
      top: 110%;
      left: 96%;
    }
  }
  .confetti-126 {
    width: 6px;
    height: 2.4px;
    background-color: #d13447;
    top: -10%;
    left: 11%;
    opacity: 0.9576657995;
    transform: rotate(317.1580709024deg);
    -webkit-animation: drop-126 4.1609891344s 0.1883407796s infinite;
            animation: drop-126 4.1609891344s 0.1883407796s infinite;
  }
  
  @-webkit-keyframes drop-126 {
    100% {
      top: 110%;
      left: 15%;
    }
  }
  
  @keyframes drop-126 {
    100% {
      top: 110%;
      left: 15%;
    }
  }
  .confetti-127 {
    width: 7px;
    height: 2.8px;
    background-color: #ffbf00;
    top: -10%;
    left: 86%;
    opacity: 1.0365703425;
    transform: rotate(335.4732358365deg);
    -webkit-animation: drop-127 4.6703649519s 0.2620071201s infinite;
            animation: drop-127 4.6703649519s 0.2620071201s infinite;
  }
  
  @-webkit-keyframes drop-127 {
    100% {
      top: 110%;
      left: 100%;
    }
  }
  
  @keyframes drop-127 {
    100% {
      top: 110%;
      left: 100%;
    }
  }
  .confetti-128 {
    width: 7px;
    height: 2.8px;
    background-color: #263672;
    top: -10%;
    left: 63%;
    opacity: 0.6639630053;
    transform: rotate(73.1274446891deg);
    -webkit-animation: drop-128 4.8295564398s 0.3540631211s infinite;
            animation: drop-128 4.8295564398s 0.3540631211s infinite;
  }
  
  @-webkit-keyframes drop-128 {
    100% {
      top: 110%;
      left: 65%;
    }
  }
  
  @keyframes drop-128 {
    100% {
      top: 110%;
      left: 65%;
    }
  }
  .confetti-129 {
    width: 2px;
    height: 0.8px;
    background-color: #263672;
    top: -10%;
    left: 23%;
    opacity: 0.5268320486;
    transform: rotate(144.873858865deg);
    -webkit-animation: drop-129 4.6786722897s 0.4660035064s infinite;
            animation: drop-129 4.6786722897s 0.4660035064s infinite;
  }
  
  @-webkit-keyframes drop-129 {
    100% {
      top: 110%;
      left: 38%;
    }
  }
  
  @keyframes drop-129 {
    100% {
      top: 110%;
      left: 38%;
    }
  }
  .confetti-130 {
    width: 1px;
    height: 0.4px;
    background-color: #263672;
    top: -10%;
    left: 65%;
    opacity: 1.3580861622;
    transform: rotate(183.0569335759deg);
    -webkit-animation: drop-130 4.3806938198s 0.1475740404s infinite;
            animation: drop-130 4.3806938198s 0.1475740404s infinite;
  }
  
  @-webkit-keyframes drop-130 {
    100% {
      top: 110%;
      left: 73%;
    }
  }
  
  @keyframes drop-130 {
    100% {
      top: 110%;
      left: 73%;
    }
  }
  .confetti-131 {
    width: 6px;
    height: 2.4px;
    background-color: #ffbf00;
    top: -10%;
    left: 33%;
    opacity: 1.4785879732;
    transform: rotate(158.2377971976deg);
    -webkit-animation: drop-131 4.8850483189s 0.7176380333s infinite;
            animation: drop-131 4.8850483189s 0.7176380333s infinite;
  }
  
  @-webkit-keyframes drop-131 {
    100% {
      top: 110%;
      left: 37%;
    }
  }
  
  @keyframes drop-131 {
    100% {
      top: 110%;
      left: 37%;
    }
  }
  .confetti-132 {
    width: 6px;
    height: 2.4px;
    background-color: #ffbf00;
    top: -10%;
    left: 23%;
    opacity: 0.9090140166;
    transform: rotate(87.3435908632deg);
    -webkit-animation: drop-132 4.2131966801s 0.3270985313s infinite;
            animation: drop-132 4.2131966801s 0.3270985313s infinite;
  }
  
  @-webkit-keyframes drop-132 {
    100% {
      top: 110%;
      left: 38%;
    }
  }
  
  @keyframes drop-132 {
    100% {
      top: 110%;
      left: 38%;
    }
  }
  .confetti-133 {
    width: 7px;
    height: 2.8px;
    background-color: #ffbf00;
    top: -10%;
    left: 38%;
    opacity: 0.85905571;
    transform: rotate(314.2196762323deg);
    -webkit-animation: drop-133 4.6921488297s 0.3399733572s infinite;
            animation: drop-133 4.6921488297s 0.3399733572s infinite;
  }
  
  @-webkit-keyframes drop-133 {
    100% {
      top: 110%;
      left: 47%;
    }
  }
  
  @keyframes drop-133 {
    100% {
      top: 110%;
      left: 47%;
    }
  }
  .confetti-134 {
    width: 1px;
    height: 0.4px;
    background-color: #d13447;
    top: -10%;
    left: 26%;
    opacity: 0.5692947962;
    transform: rotate(282.4219215591deg);
    -webkit-animation: drop-134 4.2806450868s 0.5106765096s infinite;
            animation: drop-134 4.2806450868s 0.5106765096s infinite;
  }
  
  @-webkit-keyframes drop-134 {
    100% {
      top: 110%;
      left: 37%;
    }
  }
  
  @keyframes drop-134 {
    100% {
      top: 110%;
      left: 37%;
    }
  }
  .confetti-135 {
    width: 2px;
    height: 0.8px;
    background-color: #d13447;
    top: -10%;
    left: 27%;
    opacity: 0.9148713848;
    transform: rotate(242.8458710244deg);
    -webkit-animation: drop-135 4.8321091027s 0.168473163s infinite;
            animation: drop-135 4.8321091027s 0.168473163s infinite;
  }
  
  @-webkit-keyframes drop-135 {
    100% {
      top: 110%;
      left: 35%;
    }
  }
  
  @keyframes drop-135 {
    100% {
      top: 110%;
      left: 35%;
    }
  }
  .confetti-136 {
    width: 8px;
    height: 3.2px;
    background-color: #d13447;
    top: -10%;
    left: 29%;
    opacity: 0.9543611797;
    transform: rotate(354.6580556457deg);
    -webkit-animation: drop-136 4.6244883648s 0.8282867334s infinite;
            animation: drop-136 4.6244883648s 0.8282867334s infinite;
  }
  
  @-webkit-keyframes drop-136 {
    100% {
      top: 110%;
      left: 43%;
    }
  }
  
  @keyframes drop-136 {
    100% {
      top: 110%;
      left: 43%;
    }
  }
  .confetti-137 {
    width: 4px;
    height: 1.6px;
    background-color: #263672;
    top: -10%;
    left: 18%;
    opacity: 1.4896221859;
    transform: rotate(182.5253815506deg);
    -webkit-animation: drop-137 4.2070486888s 0.0762466971s infinite;
            animation: drop-137 4.2070486888s 0.0762466971s infinite;
  }
  
  @-webkit-keyframes drop-137 {
    100% {
      top: 110%;
      left: 24%;
    }
  }
  
  @keyframes drop-137 {
    100% {
      top: 110%;
      left: 24%;
    }
  }
  .confetti-138 {
    width: 4px;
    height: 1.6px;
    background-color: #d13447;
    top: -10%;
    left: 54%;
    opacity: 1.3634216789;
    transform: rotate(120.2202885679deg);
    -webkit-animation: drop-138 4.9729164175s 0.076771587s infinite;
            animation: drop-138 4.9729164175s 0.076771587s infinite;
  }
  
  @-webkit-keyframes drop-138 {
    100% {
      top: 110%;
      left: 64%;
    }
  }
  
  @keyframes drop-138 {
    100% {
      top: 110%;
      left: 64%;
    }
  }
  .confetti-139 {
    width: 4px;
    height: 1.6px;
    background-color: #ffbf00;
    top: -10%;
    left: 31%;
    opacity: 1.0490764276;
    transform: rotate(70.8375205836deg);
    -webkit-animation: drop-139 4.0955918612s 0.1721904307s infinite;
            animation: drop-139 4.0955918612s 0.1721904307s infinite;
  }
  
  @-webkit-keyframes drop-139 {
    100% {
      top: 110%;
      left: 33%;
    }
  }
  
  @keyframes drop-139 {
    100% {
      top: 110%;
      left: 33%;
    }
  }
  .confetti-140 {
    width: 5px;
    height: 2px;
    background-color: #ffbf00;
    top: -10%;
    left: 100%;
    opacity: 1.1388436883;
    transform: rotate(159.3571076183deg);
    -webkit-animation: drop-140 4.3856210338s 0.6444470543s infinite;
            animation: drop-140 4.3856210338s 0.6444470543s infinite;
  }
  
  @-webkit-keyframes drop-140 {
    100% {
      top: 110%;
      left: 109%;
    }
  }
  
  @keyframes drop-140 {
    100% {
      top: 110%;
      left: 109%;
    }
  }
  .confetti-141 {
    width: 6px;
    height: 2.4px;
    background-color: #d13447;
    top: -10%;
    left: 60%;
    opacity: 0.5033007016;
    transform: rotate(112.2920949041deg);
    -webkit-animation: drop-141 4.6762866835s 0.6086769668s infinite;
            animation: drop-141 4.6762866835s 0.6086769668s infinite;
  }
  
  @-webkit-keyframes drop-141 {
    100% {
      top: 110%;
      left: 67%;
    }
  }
  
  @keyframes drop-141 {
    100% {
      top: 110%;
      left: 67%;
    }
  }
  .confetti-142 {
    width: 4px;
    height: 1.6px;
    background-color: #d13447;
    top: -10%;
    left: 76%;
    opacity: 1.3047382377;
    transform: rotate(77.8284680272deg);
    -webkit-animation: drop-142 4.3969894898s 0.9284719552s infinite;
            animation: drop-142 4.3969894898s 0.9284719552s infinite;
  }
  
  @-webkit-keyframes drop-142 {
    100% {
      top: 110%;
      left: 81%;
    }
  }
  
  @keyframes drop-142 {
    100% {
      top: 110%;
      left: 81%;
    }
  }
  .confetti-143 {
    width: 8px;
    height: 3.2px;
    background-color: #263672;
    top: -10%;
    left: 84%;
    opacity: 0.9063652974;
    transform: rotate(287.7581157134deg);
    -webkit-animation: drop-143 4.6115453505s 0.6672257161s infinite;
            animation: drop-143 4.6115453505s 0.6672257161s infinite;
  }
  
  @-webkit-keyframes drop-143 {
    100% {
      top: 110%;
      left: 94%;
    }
  }
  
  @keyframes drop-143 {
    100% {
      top: 110%;
      left: 94%;
    }
  }
  .confetti-144 {
    width: 5px;
    height: 2px;
    background-color: #263672;
    top: -10%;
    left: 57%;
    opacity: 0.5151658338;
    transform: rotate(93.2466584863deg);
    -webkit-animation: drop-144 4.8108505673s 0.276854848s infinite;
            animation: drop-144 4.8108505673s 0.276854848s infinite;
  }
  
  @-webkit-keyframes drop-144 {
    100% {
      top: 110%;
      left: 66%;
    }
  }
  
  @keyframes drop-144 {
    100% {
      top: 110%;
      left: 66%;
    }
  }
  .confetti-145 {
    width: 2px;
    height: 0.8px;
    background-color: #263672;
    top: -10%;
    left: 27%;
    opacity: 1.0972207703;
    transform: rotate(56.8681269479deg);
    -webkit-animation: drop-145 4.3032380329s 0.9150684548s infinite;
            animation: drop-145 4.3032380329s 0.9150684548s infinite;
  }
  
  @-webkit-keyframes drop-145 {
    100% {
      top: 110%;
      left: 30%;
    }
  }
  
  @keyframes drop-145 {
    100% {
      top: 110%;
      left: 30%;
    }
  }
  .confetti-146 {
    width: 5px;
    height: 2px;
    background-color: #263672;
    top: -10%;
    left: 22%;
    opacity: 1.4641348202;
    transform: rotate(216.0555206173deg);
    -webkit-animation: drop-146 4.874365412s 0.1967559402s infinite;
            animation: drop-146 4.874365412s 0.1967559402s infinite;
  }
  
  @-webkit-keyframes drop-146 {
    100% {
      top: 110%;
      left: 35%;
    }
  }
  
  @keyframes drop-146 {
    100% {
      top: 110%;
      left: 35%;
    }
  }
  .confetti-147 {
    width: 5px;
    height: 2px;
    background-color: #ffbf00;
    top: -10%;
    left: 9%;
    opacity: 0.797054431;
    transform: rotate(202.6154314556deg);
    -webkit-animation: drop-147 4.6966501988s 0.9790891227s infinite;
            animation: drop-147 4.6966501988s 0.9790891227s infinite;
  }
  
  @-webkit-keyframes drop-147 {
    100% {
      top: 110%;
      left: 21%;
    }
  }
  
  @keyframes drop-147 {
    100% {
      top: 110%;
      left: 21%;
    }
  }
  .confetti-148 {
    width: 2px;
    height: 0.8px;
    background-color: #263672;
    top: -10%;
    left: 54%;
    opacity: 1.3752401088;
    transform: rotate(125.5700111811deg);
    -webkit-animation: drop-148 4.5722329818s 0.3795039254s infinite;
            animation: drop-148 4.5722329818s 0.3795039254s infinite;
  }
  
  @-webkit-keyframes drop-148 {
    100% {
      top: 110%;
      left: 69%;
    }
  }
  
  @keyframes drop-148 {
    100% {
      top: 110%;
      left: 69%;
    }
  }
  .confetti-149 {
    width: 1px;
    height: 0.4px;
    background-color: #ffbf00;
    top: -10%;
    left: 53%;
    opacity: 1.151834275;
    transform: rotate(97.9100502456deg);
    -webkit-animation: drop-149 4.2989707812s 0.9009703322s infinite;
            animation: drop-149 4.2989707812s 0.9009703322s infinite;
  }
  
  @-webkit-keyframes drop-149 {
    100% {
      top: 110%;
      left: 54%;
    }
  }
  
  @keyframes drop-149 {
    100% {
      top: 110%;
      left: 54%;
    }
  }
  .confetti-150 {
    width: 4px;
    height: 1.6px;
    background-color: #ffbf00;
    top: -10%;
    left: 50%;
    opacity: 0.8215268269;
    transform: rotate(213.4721513662deg);
    -webkit-animation: drop-150 4.941781903s 0.4147572578s infinite;
            animation: drop-150 4.941781903s 0.4147572578s infinite;
  }
  
  @-webkit-keyframes drop-150 {
    100% {
      top: 110%;
      left: 60%;
    }
  }
  
  @keyframes drop-150 {
    100% {
      top: 110%;
      left: 60%;
    }
  }